//COLORES
$primary: #FF7E36;
$secondary:#333A73;
$light:#FFF;
$dark:#000;
$softLight:#F1F1F1;
$refresh:#059212;
$error:#ca3636;

//VARIABLE
$boxShadow:-10px 10px 5px rgb(217, 217, 217);
$transition:all ease-in-out .2s;

@font-face {
    font-family: Robo;
    src: url(../fonts/Roboto-Regular.ttf);
}


html{
    font-size: 62.5%;
}
body{
    font-family: "Robo";
    scroll-behavior: smooth;
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.info__container{
    position: relative;
    width: 100%;
    height: 100vh;
    .info__content{
        // max-width: 1090px !important;
        width: 90%;
        height: 100%;
        margin: 0 auto;
        .headline{
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            width: 100%;
            gap: 5rem;
            background: $light;
            padding: 4rem 0;
            justify-content: space-around;
            .navbar{
                display: flex;
                align-items: center ;
                gap: 5rem;
                .navbar__item{
                    font-size: 2rem;
                    font-weight: 600;
                    padding: 1rem 3rem;
                    cursor: pointer;
                    border-radius: 1rem;
                    width: 100%;
                    background: $light;
                    color: $dark;
                    transition: $transition;
                    // &::after{
                    //     content: "";
                    //     width: 100%;
                    //     display: block;
                    //     height: 0.3rem;
                    //     background: transparent;
                    //     transition: $transition;
                    //     border-radius: 2rem;
                    // }
                    &.active{
                        width: 100%;
                        background: $primary;
                        color: $light;
                        transition: $transition;
                        // &::after{
                        //     content: "";
                        //     width: 100%;
                        //     display: block;
                        //     height: 0.3rem;
                        //     background: $primary;
                        //     transition: $transition;
                        // }
                    }
                    &.inactive{
                        width: 100%;
                        background: $light;
                        color: $dark;
                        transition: $transition;
                        // &::after{
                        //     content: "";
                        //     width: 10%;
                        //     display: block;
                        //     height: 0.3rem;
                        //     background: $light;
                        //     transition: $transition;
                        // }
                    }
                }
            }
            .navbar__search{
                display: flex;
                align-items: center;
                gap: 2rem;
                border-bottom: .2rem solid $dark;
                .search__icon{
                    font-size: 2rem;
                }
                input{
                    padding: 1rem 2rem;
                    border: none;
                    outline: none;
                    font-size: 1.5rem;
                    font-weight: 600;
                }
            }
        }
        .info{
            width: 100%;
            height: 10rem;
            margin-top: 15rem;
            .info__list{
                display: flex;
                width: 100%;
                flex-direction: column;
                gap: 2rem;
                margin-top: 5rem;
                padding: 1rem 2rem;
                .table{
                    width: 100%;
                    border-collapse: collapse;
                    table-layout: auto;
                    .info__headline{
                        background: $secondary;
                        color: $light;
                        .headline__item{
                            font-size: 1.5rem;
                            padding: 1rem 2rem;
                            font-weight: 600;
                            border: .2rem solid $dark;
                            white-space: nowrap;
                            text-align: center;
                        }
                    }
                    .info__body{
                        .body__item{
                            font-weight: 600;
                            &:nth-child(even){
                                background-color: #F0EBE3;
                            }
                            .item__title{
                                font-size: 1.5rem;
                                border: .2rem solid $dark;
                                padding: 1rem .5rem;
                                text-align: center;
                                // text-align: left;
                                word-break: break-word;
                                .active{
                                    font-size: 2rem;
                                    color: $primary;
                                }
                                .inactive{
                                }
                                .online{
                                    color: $refresh;
                                    font-size: 2rem;

                                }
                                .offline{
                                    color: $error;
                                    font-size: 2rem;

                                }

                            }
                        }
                    }
                }
            }
        }
    }
    .info__alert{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        .alert__content{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: $light;
            padding: 3rem 4rem;
            width: 50%;
            gap: 4rem;
            border-radius: 1rem;
            .input__field{
                width: 100%;
                display: flex;
                align-items: center;
                .field__headline{
                    font-size: 1.5rem;
                    font-weight: 600;
                    width: 70%;
                }
                input {
                    width: 100%;
                    border: none;
                    outline: none;
                    border-bottom: .2rem solid $dark;
                    font-size: 2rem;
                    font-weight: 600;
                    padding: 0 2rem;
                }
                .machineID__list{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    gap:.5rem;
                    .machineID{
                        padding: .5rem;
                        font-size: 1.5rem;
                        border-radius: .2rem;
                        font-weight: 600;
                    }
                }
            }
            .alert__btn{
                font-size: 2rem;
                text-transform: uppercase;
                background: $primary;
                color: $light;
                padding: 1rem 2rem;
                border-radius: .5rem;
                font-weight: 600;
            }
            .alert__close{
                position: absolute;
                top: -1rem;
                right: -1rem;
                color: $primary;
                padding: .5rem;
                background: $light;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.5rem;
                border: .2rem solid $primary;
                cursor: pointer;
            }
        }

    }
    .info__nav{
        bottom: 3rem;
        right: 3rem;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
        gap: 3rem;
        .nav__item{
            font-size: 1.5rem;
            text-transform: uppercase;
            font-weight: 600;
            padding: 1.5rem 2rem;
            border-radius: 1rem;
            cursor: pointer;
            &.add{
                background: $primary;
                color: $light;
            }
            &.refresh{
                background: $refresh;
                color: $light;
            }
            &.search{
                
            }
        }
    }
}