.video{
    width: 100%;
    height: 100vh;
    background-color: tomato;
    overflow: hidden;
    .video__item{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}