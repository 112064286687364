//COLORES
$primary: #FF7E36;
$secondary:#333A73;
$light:#FFF;
$dark:#000;
$softLight:#F6F6F6;

//VARIABLE
$boxShadow:-10px 10px 5px rgb(217, 217, 217);

@font-face {
    font-family: Robo;
    src: url(../fonts/Roboto-Regular.ttf);
}


html{
    font-size: 62.5%;
}
body{
    font-family: "Robo";
    scroll-behavior: smooth;
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.admin__manage_container{
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: #efefef;
    padding-bottom: 20rem;
    .admin__manage_content{
        max-width: 950px !important;
        height: 100%;
        margin: 0 auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10rem;
        .admin__manage_header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10rem 0;
            background-color: #efefef;
            .admin__manage_headline{
                font-size: 5rem;
                .headline__user{
                    font-weight: 900;
                }
            }
            .admin__manage_control{
                display: flex;
                flex-direction: column;
                gap: 3rem;
                justify-content: flex-end;
                align-items: flex-end;
                .admin__manage_create{
                    background: $primary;
                    padding: 2rem 4rem;
                    font-size: 3rem;
                    box-shadow: $boxShadow;
                    border-radius: 2rem;
                    text-transform: uppercase;
                    font-weight: 600;
                    color: $light;
                }
                .admin__manage_deives{
                    display: flex;
                    align-items: flex-end;
                    gap: 2rem;
                    .headline_count{
                        font-size: 2.5rem;
                        font-weight: 600;
                    }
                    .headline__search{
                        font-size: 3rem;
                        border: none;
                        border-bottom: .2rem solid $dark;
                        background-color: transparent;
                        padding: 1rem 2rem;
                        outline: none;
                    }
                }
            }
        }
        .admin__controller{
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .controller__item{
                width: 40%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 5rem;
                background: $primary;
                padding: 5rem 0;
                border-radius: 2rem;
                .item__icon{
                    font-size: 10rem;
                    color: $light;
                }
                .item__text{
                    font-size: 4rem;
                    font-weight: 600;
                }
            }
        }
        .returned__config{
            width: 90%;
            background: $primary;
            padding: 5rem 0;
            border-radius: 2rem;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .returned__config_icon{
                font-size: 10rem;
                color: $light;
            }
            .returned__config_text{
                font-size: 4rem;
                font-weight: 600;
            }
        }
        // .admin__manage_list{
        //     width: 100%;
        //     height: 100%;
        //     display: flex;
        //     justify-content: flex-start;
        //     align-items: center;
        //     flex-wrap: wrap;
        //     gap: 5rem;
        //     .item{
        //         width: 100%;
        //         height: 100%;
        //         display: flex;
        //         align-items: center;
        //         justify-content: space-between;
        //         padding: 2rem 3rem;
        //         background: $light;
        //         border-radius: 2rem;
        //         .item__image{
        //             width: 20%;
        //             height: 20vh;
        //             object-fit: cover;
        //             border-radius: 2rem;
        //         }
        //         .item__info{
        //             width: 70%;
        //             display: flex;
        //             flex-direction: column;
        //             justify-content: space-between;
        //             height: 100%;
        //             gap: 5rem;
        //             .item__name{
        //                 font-size: 3rem;
        //             }
        //             .item__price{
        //                 font-size: 3rem;
        //                 font-weight: 600;
        //             }
        //             .item__info_bottom{
        //                 display: flex;
        //                 justify-content: space-between;
        //                 align-items: center;
        //                 .item__info_slot{
        //                     display: flex;
        //                     justify-content: center;
        //                     align-items: center;
        //                     border-radius: 1rem;
        //                     font-size: 3rem;
        //                     gap: 1rem;
        //                     font-weight: 600;
        //                 }
        //                 .item__controller{
        //                     display: flex;
        //                     gap: 4rem;
        //                     align-items: center;
        //                     .controller{
        //                         width: 5rem;
        //                         height: 5rem;
        //                         display: flex;
        //                         justify-content: center;
        //                         align-items: center;
        //                         border-radius: 1rem;
        //                         &.open{
        //                             width: 13rem;
        //                             background: $secondary;
        //                             border: .3rem solid $secondary; 
        //                         }
        //                         &.edit{
        //                             background: #FFF;
        //                             border: .3rem solid $dark;                                
        //                         }
        //                         &.delete{
        //                             background: $dark;
        //                             border: .3rem solid $dark;                                
        //                         }
        //                         .controller__icon{
        //                             font-size: 2rem;
        //                             &.open{
        //                                 text-transform: uppercase;
        //                                 color: $light;
        //                                 font-weight: 600;
        //                             }
        //                             &.edit{
        //                                 color: $dark;
        //                             }
        //                             &.delete{
        //                                 color: $light;
        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        //     .no__item{
        //         font-size: 5rem;
        //         text-align: center;
        //         width: 100%;
        //     }
        // }
    }
    .logout{
        position: fixed;
        bottom: 3rem;
        right: 3rem;
        background-color: $primary;
        font-size: 3rem;
        padding: 1rem 3rem;
        text-transform: uppercase;
        color: $light;
        border-radius: 2rem;
        box-shadow: $boxShadow;
    }
    // .boxes__control{
    //     position: fixed;
    //     bottom: 3rem;
    //     left: 3rem;
    //     background-color: $primary;
    //     padding: 1.5rem;
    //     border-radius: 2rem;
    //     box-shadow: $boxShadow;
    //     .boxes__control_icon{
    //         font-size: 4.5rem;
    //         color: $dark;
    //     }
    // }
    // .machine__config{
    //     position: fixed;
    //     bottom: 3rem;
    //     left: 15rem;
    //     background-color: $primary;
    //     padding: 2rem;
    //     border-radius: 2rem;
    //     // box-shadow: $boxShadow;
    //     .machine__config_icon{
    //         font-size: 3.5rem;
    //         color: $dark;
    //     }
    // }
    // .returned__config{
    //     position: fixed;
    //     bottom: 3rem;
    //     left: 27rem;
    //     background-color: $primary;
    //     padding: 2rem;
    //     border-radius: 2rem;
    //     // box-shadow: $boxShadow;
    //     .returned__config_icon{
    //         font-size: 3.5rem;
    //         color: $dark;
    //     }
    // }
    .modal{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000, $alpha: .7);
        .modal__content{
            width: 100%;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            .modal__image{
                background: #EEEEEE;
                width: 40%;
                height: 85vh;
                border-top-left-radius: 2rem;
                border-bottom-left-radius: 2rem;
                overflow: hidden;
                .image__sample{
                    font-size: 10rem;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 600;
                    text-align: center;
                }
                .image{
                    width: 100%;
                    height: 100%;
                }
            }
            .modal__input{
                position: relative;
                width: 50%;
                height: 85vh;
                background-color: #fff;
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-direction: column;
                border-top-right-radius: 2rem;
                border-bottom-right-radius: 2rem;
                .modal__input_headline{
                    font-size: 4rem;
                    font-weight: 600;     
                    padding: 0 3rem;
                    width: 90%;            
                }
                .modal__input_form{
                    padding: 0 3rem;    
                    display: flex;
                    flex-direction: column;
                    gap: 4rem;        
                    width: 90%;            
                    .modal__input_field{
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;  
                        .field__lable{
                            font-size: 2.5rem;
                        }
                        input{
                            width: 80%;
                            padding: 1rem 2rem;
                            border: none;
                            border-bottom: .2rem solid $dark;
                            font-size: 3rem;
                            font-weight: 600;
                            outline: none;
                        }
                    }
                }
                .modal__slot_container{
                    display: flex;
                    flex-direction: column;
                    width: 90%;
                    gap: 2rem;
                    .modal__input_slots{
                        .slots{
                            display: flex;
                            align-items: center;
                            // justify-content: center;
                            gap: 2rem;
                            flex-wrap: wrap;
                            .slot{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 2.5rem;
                                width: 5rem;
                                height: 5rem;
                                border-radius: .5rem;
                            }
                        }
                    }
                    .field__lable{
                        font-size: 2.5rem;
                    }
                }
                .modal__input_btn{
                    background-color: $primary;
                    width: 90%;
                    font-size: 3rem;
                    height: 6rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    color: $light;
                    border-radius: 1rem;
                    outline: none;
                }
                .modal__close{
                    position: absolute;
                    top: -1rem;
                    right: -1rem;
                    width: 5rem;
                    height: 5rem;
                    background: $primary;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 1rem;
                    .modal__close_icon{
                        color: $light;
                        font-size: 3rem;
                    }
                }
            }
            .delete__container{
                display: flex;
                flex-direction: column;
                background: $light;
                padding: 4rem;
                gap: 10rem;
                align-items: center;
                border-radius: 2rem;
                width: 70%;
                .delete__header{
                    font-size: 4rem;
                    text-align: center;
                    width: 70%;
                }
                .delete__controller{
                    display: flex;
                    align-items: center;
                    width: 70%;
                    justify-content: space-between;
                    .delete__btn{
                        width: 30%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 4rem;
                        padding: 1rem 2rem;
                        border-radius: 2rem;
                        &.confirm{
                            background: $primary;
                            color:$light ;
                        }
                        &.cancel{
                            color: $primary;
                        }
                    }
                }
            }
            .open__container{
                display: flex;
                flex-direction: column;
                background: $light;
                padding: 4rem;
                gap: 10rem;
                align-items: center;
                border-radius: 2rem;
                width: 70%;
                .open__header{
                    font-size: 4rem;
                    font-weight: 600;
                    text-align: center;
                    .open__header_recommend{
                        font-size: 3rem;
                        color: #c2c2c2;
                        font-style: italic;
                    }
                }
                .open__pin{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    gap: 2rem;
                    input{
                        width: 40%;
                        outline: none;
                        border: none;
                        border-bottom: .2rem solid #000;
                        font-size: 4rem;
                        text-align: center;
                    }
                    .open__pin_status{
                       font-size: 3rem;
                       color: #E72929; 
                    }  
                }
                .open__controller{
                    display: flex;
                    align-items: center;
                    width: 70%;
                    justify-content: space-between;
                    .open__btn{
                        width: 30%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 4rem;
                        padding: 1rem 2rem;
                        border-radius: 2rem;
                        &.confirm{
                            background: $primary;
                            color:$light ;
                        }
                        &.cancel{
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}