//COLORES
$primary: #FF7E36;
$secondary:#333A73;
$light:#FFF;
$dark:#000;
$softLight:#F6F6F6;
$teal:#018F8C;
$softGreen:#019946;
$transition: all ease-in-out 2s;

//VARIABLE
$boxShadow:-10px 10px 5px rgb(217, 217, 217);

@font-face {
    font-family: Robo;
    src: url(../fonts/Roboto-Regular.ttf);
}


html{
    font-size: 62.5%;
}
body{
    font-family: "Robo";
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.method__container{
    position: relative;
    width: 100%;
    height: 100vh;
    .method__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5rem;
        .header__back{
            width: 7rem;
            height: 7rem;
            border-radius: 50%;
            background-color: $primary;
            display: flex;
            justify-content: center;
            align-items: center;
            .header__back_icon{
                font-size: 5rem;
                color: $light;
            }
        }
        .header__title{
            font-size: 6rem;
            font-weight: 900;
        }
    }
    .method__content{
        max-width: 950px !important;
        margin: 0 auto;
        padding: 0 10rem;
        .method__summary{
            margin-top: 4rem;
            .method__summary_content{
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: $light;
                border: .3rem solid $dark;
                padding: 5rem 2rem;
                border-radius: 2rem;
                box-shadow: $boxShadow;
                .summary__image{
                    width: 20%;
                    height: 100%;
                    border-radius: 1rem;
                }
                .summary__info{
                    width: 70%;
                    height: 23rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .summary__price{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .info__item{
                            font-size: 3.5rem;
                            font-weight: 600;
                        }
                    }
                    .summary__line{
                        width: 100%;
                        height: .5rem;
                        background-color: #E2E3E7;
                    }
                    .summary__total{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .info__total{
                            font-size: 5.5rem;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .method__transaction{
            margin-top: 8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 5rem;
            .method__cash_topup{
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                background: $softGreen;
                box-shadow: $boxShadow;
                border-radius: 2rem;
                padding:3rem 0;
                color: $light;
                .method__cash_topup_headline{
                    padding:0 3rem;
                    font-size: 4rem;
                    font-weight: 600;
                }
                .method__cash_topup_number{
                    padding:0 3rem;
                    font-size: 4rem;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                }
            }
            .method__cash_enough{
                padding: 2rem 15rem;
                background: $primary;
                color: $light  ;
                font-size: 4rem;
                border-radius: 2rem;
                text-transform: uppercase;
                box-shadow: $boxShadow;
                margin-top: 5rem;
                text-decoration: none;
            }
            .method__cash_notice{
                font-size: 3.5rem;
                text-align: center;
                color:$dark;
                font-weight: 600;
                line-height: 5rem;
            }
            .method__cash{
                position: relative;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-top: 4rem;
                .layer__faded{
                    width: 100%;
                    height: 10rem;
                    background: $light;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .money__reader{
                        position: relative;
                        font-size: 3rem;
                        font-weight: 600;
                        transition: all 1.2s ease-in-out;
                        opacity: 1;
                        font-size: 4rem;    
                        top: 1rem;     
                        opacity: 0;
                        background: $softGreen;
                        color: $light;
                        border-radius: 1rem;
                        padding: 1rem 3rem;
                        &.faded {
                        top: -6.5rem;
                        opacity: 1;  
                        }
                    }
                }
                .layer__faded_in{
                    width: 100%;
                    height: 12rem;
                    background: $light;
                    margin-top: 12rem;
                }
                .banknote{
                    width: 20%;
                    background: $light;
                    border: .5rem solid $dark;
                    height: 1.5rem;
                    border-radius: 1rem;
                    border-bottom: 1rem solid $dark;
                }
                .cash{
                    position: absolute;
                    left: 41.5%;
                    font-size: 12rem;
                    transform: rotate(90deg);
                    z-index: -1;
                    animation: moneyMove 2s infinite forwards;
                    color: $softGreen;
                }
            }
            .method__bank{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: 3rem;
                gap: 5rem;
                .method__desc{
                    font-size: 4rem;
                    text-align: center;
                }
                .method__banking{
                    width: 35rem;
                    height: 35rem;
                    // background-color: $primary;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: .5rem solid $primary;
                    border-radius: 2rem;
                    box-shadow: $boxShadow;
                    overflow: hidden;
                    .qr__banking{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .gtc__content{
                width: 100%;
                background-color: $softLight;
                box-shadow: $boxShadow;
                border-radius: 2rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: .5rem;
                .gtc__item{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 3rem 0rem;
                    width: 90%;
                    .gtc__headline{
                        font-size: 3rem;
                    }
                    .gtc__coin{
                        display: flex;
                        align-items: center;
                        gap: 2rem;
                        .gtc__coin_icon{
                            font-size: 4rem;
                            color: $primary;
                            font-weight: 900;
                        }
                        .gtc__coin_number{
                            font-size: 4rem;
                            font-weight: 600;
                            &.error{
                                color: #D04848;
                            }
                        }
                    }
                }
                .gtc__line{
                    width: 90%;
                    height: .2rem;
                    background-color: #E2E3E7;
                }
            }
            .gtc__enough{
                padding: 2rem 10rem;
                    background-color: $primary;
                    font-size: 5rem;
                    border-radius: 2rem;
                    box-shadow: $boxShadow;
                    color: $light;
                    font-weight: 600;
                    margin-top: 5rem;
                    text-transform: uppercase;
                    text-decoration: none;
            }
            .gtc__notenough{
                display: flex;
                flex-direction: column ;
                align-items: center;
                justify-content: center;
                gap: 4rem;
                .gtc__notenough_text{
                    text-align: center;
                    font-size: 3.5rem;
                    font-weight: 600;
                    color:#D04848 ;
                }
                .gtc__notenough_btn{
                    padding: 2rem 10rem;
                    background-color: $light;
                    font-size: 4rem;
                    border-radius: 2rem;
                    box-shadow: $boxShadow;
                    color: $primary;
                    text-decoration: none;
                    border: .5rem solid $primary;
                    font-weight: 600;
                }
            }
        }
    }
    .withdraw{
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        background-color: rgba($color: #000000, $alpha: .6);
        z-index: 1;
        .withdraw__container{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .withdraw__content{
                background: $light;
                width: 80%;
                padding: 4rem 3rem;
                border-radius: 2rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10rem;
                .withdraw__header{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 5rem;
                    .header__headline{
                        font-size: 4rem;
                        font-weight: 600;
                        color: $dark;
                    }
                    .header__remain{
                        font-size: 6rem;
                        color: $softGreen;
                        font-weight: 600;
                    }
                }
                .withdraw__btns{
                    display: flex;
                    align-items: flex-start;
                    // gap: 3rem;
                    justify-content: space-between;
                    width: 100%;
                    .btn__item{
                        width: 45%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex-direction: column;
                        font-size: 3.5rem;
                        font-weight: 600;
                        .btn__gtc{
                            background: $primary;
                            color: $light;
                            padding: 2rem 3rem;
                            border-radius: 1rem;
                        }
                        .btn__cash{
                            background: $softGreen;
                            color: $light;
                            padding: 2rem 3rem;
                            border-radius: 1rem;
                            width: 80%; // ??
                            text-align: center;
                        }
                        .btn__cash_text{
                            margin-top: 2rem;
                            font-size: 2rem;
                        }
                    }

                }
            }
        }
    }
    .take__cash{
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        background-color: rgba($color: #000000, $alpha: .6);
        z-index: 1;
        .take__cash_container{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .take__cash_content{
                background: $light;
                width: 60%;
                padding: 4rem 3rem;
                border-radius: 2rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 6rem;
                .take__cash_nav{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .nav__arrow{
                        width: 6rem;
                        height: 6rem;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: $primary;
                        .nav__icon{
                            font-size: 3rem;
                            color: $light;
                        }
                    }
                    .nav__title{
                        font-size: 4rem;
                        font-weight: 600;
                    }
                }
                .take__cash_line{
                    width: 90%;
                    height: .2rem;
                    background:#bababa;
                    border-radius: 2rem;
                }
                .take__cash_summary{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 3rem;
                    .summary__item{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .headline{
                            font-size: 3rem;
                            font-weight: 600;
                        }
                        .number{
                            font-size: 3rem;
                            font-weight: 600;
                        }
                    }
                }
                .take__cash_amount{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .amount__headline{
                        font-size: 3rem;
                        font-weight: 600;
                    }
                    .amount__input{
                        display: flex;
                        align-items: center;
                        font-size: 4rem;
                        justify-content: flex-end;
                        .input{
                            width: 50%;
                            font-size: 4rem;
                            text-align: center;
                            outline: none;
                            border: none;
                            &.success{
                                border-bottom: .3rem solid $dark;
                            }
                            &.err{
                                border-bottom: .3rem solid #D04848;
                            }
                        }
                    }
                }
                .take__cash_amount_err{
                    font-size: 3rem;
                    color: #D04848;
                    font-weight: 600;
                    text-align: center;
                }
                .take__cash_btn{
                    font-size: 3rem;
                    background: $primary;
                    padding: 2rem 5rem;
                    border-radius: 1rem;
                    color: $light;
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
        }
    }
    .state{
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 3rem;
        left: 3rem;
        .state__item{
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $dark;
            border: .5rem solid #cbcbcb;
            .state__icon{
                font-size: 3rem;
                color: $dark;
            }
            &.active{
                background-color: $secondary;    
                border: 1.3rem solid $secondary;
            .state__icon{
                font-size: 3rem;
                color: $light;
            }
            }
            &.done{
                background-color: $secondary;
                color: $light; 
                border: .5rem solid $secondary;

                .state__icon{
                    font-size: 3rem;
                    color: $light;
                }
            }
            &.error{
                background: #D04848;
                .state__icon{
                    font-size: 3rem;
                    color: $dark;
                }
            }
        }
        .state__line{
            width: 4rem;
            height: .3rem;
            background-color: #000;
        }
    }
    .method__waiting{
        position: absolute;
        bottom: 5rem;
        right: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        .method__waiting_text{
            font-size: 2rem;
            font-style: italic;
        }
        .count__down{
            font-size: 2rem;
            font-style: italic;
        }
    }
    .currency{
        color: $primary;
        font-weight: 600;
        margin-left: 1rem;
    }
    .loading__container{
        position: fixed;
        top: 0;
        width: 100%;
        min-height: 100vh;
        background-color: rgba($color: #686868, $alpha: .9);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 15rem;
        .dots{
            display: flex;
            align-items: center;
            gap: 5rem;
            .dot{
                position: relative;
                width: 3rem;
                height: 3rem;
                border-radius: 50%;
                background: $primary;
                border: .5rem solid $light;
                animation: dotMove 2s forwards infinite;
                transition: $transition;
                top: 0;
                &.first{}
                &.second{
                    animation-delay: .2s;
                }
                &.third{
                    animation-delay: .4s;
                }
            }
        }
        .loading__text{
            font-size: 6rem;
            font-weight: 600;
            color: $light;
            text-align: center;
        }
    }
    .valid{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba($color: #000000, $alpha: 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        .valid__content{
            background: $light;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 7rem 4rem;
            border-radius: 2rem;
            gap: 5rem;
            .valid__icon{
                font-size: 10rem;
                color: #D04848;
            }
            .valid__headline{
                font-size: 4rem;
                text-align: center;
                font-weight: 600;
                width: 90%;
                line-height: 6rem;
            }
            .valid__btn{
                background: $primary;
                color: $light;
                font-size: 3rem;
                padding: 1.5rem 4rem;
                text-transform: uppercase;
                font-weight: 600;
                border-radius: 1rem;
            }
        }
    }
}

@keyframes moneyMove {
    0%{
        top: 24rem;
    }
    50%{
        top: 9rem;
    }
    65%{
        top: 9rem;
    }
    100%{
        top: -1rem;
    }
  }

  @keyframes dotMove {
    0%{
        top: 0rem;
    }
    50%{
        top: -5rem
    }
    100%{
        top: 0rem;
    }
  }