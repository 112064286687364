//COLORES
$primary: #FF7E36;
$secondary:#333A73;
$light:#FFF;
$dark:#000;
$softLight:#F6F6F6;

//VARIABLE
$boxShadow:-10px 10px 5px rgb(217, 217, 217);

@font-face {
    font-family: Robo;
    src: url(../fonts/Roboto-Regular.ttf);
}


html{
    font-size: 62.5%;
}
body{
    font-family: "Robo";
    scroll-behavior: smooth;
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.boxes__container{
    width: 100%;
    min-height: 100vh;
    position: relative;
    background-color: #efefef;
    .boxes__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5rem;
        .header__back{
            width: 7rem;
            height: 7rem;
            border-radius: 50%;
            background-color: $primary;
            display: flex;
            justify-content: center;
            align-items: center;
            .header__back_icon{
                font-size: 5rem;
                color: $light;
            }
        }
        .header__title{
            font-size: 6rem;
            font-weight: 900;
        }
    }
    .boxes__content{
        max-width: 950px !important;
        height: 100%;
        margin: 0 auto;
        position: relative;
        .boxes__list{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 2rem;
            margin-top: 5rem;
            .box__number{
                width: 18%;
                background-color: #fff;
                height: 17rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3rem;
                text-align: center;
                .number{
                    font-size: 4.5rem;
                    word-wrap: break-word;
                    font-weight: 600;
                }
            }
        }
    }
    .open__all{
        position: fixed;
        bottom: 3rem;
        right: 5rem;
        background: $primary;
        width: 20rem;
        height: 8rem;
        border-radius: 2rem;
        box-shadow: $boxShadow;
        display: flex;
        justify-content: center;
        align-items: center;
        .open__all_text{
            font-size: 3rem;
            font-weight: 600;
            color: $light;
            text-transform: uppercase;
        }
    }
    .modal{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000, $alpha: .7);
        .modal__content{
            width: 100%;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            .open__container{
                display: flex;
                flex-direction: column;
                background: $light;
                padding: 4rem;
                gap: 10rem;
                align-items: center;
                border-radius: 2rem;
                width: 70%;
                .open__header{
                    font-size: 4rem;
                    font-weight: 600;
                    text-align: center;
                    .open__header_recommend{
                        font-size: 3rem;
                        color: #c2c2c2;
                        font-style: italic;
                    }
                }
                .open__pin{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    gap: 2rem;
                    input{
                        width: 40%;
                        outline: none;
                        border: none;
                        border-bottom: .2rem solid #000;
                        font-size: 4rem;
                        text-align: center;
                    }
                    .open__pin_status{
                       font-size: 3rem;
                       color: #E72929; 
                    }  
                }
                .open__controller{
                    display: flex;
                    align-items: center;
                    width: 70%;
                    justify-content: space-between;
                    .open__btn{
                        width: 30%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 4rem;
                        padding: 1rem 2rem;
                        border-radius: 2rem;
                        &.confirm{
                            background: $primary;
                            color:$light ;
                        }
                        &.cancel{
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}