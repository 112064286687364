//COLORES
$primary: #FF7E36;
$secondary:#333A73;
$light:#FFF;
$dark:#000;
$softLight:#F6F6F6;

//VARIABLE
$boxShadow:-10px 10px 5px rgb(217, 217, 217);

@font-face {
    font-family: Robo;
    src: url(../fonts/Roboto-Regular.ttf);
}


html{
    font-size: 62.5%;
}
body{
    font-family: "Robo";
    scroll-behavior: smooth;
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.machine__container{
    width: 100%;
    min-height: 100vh;
    position: relative;
    background-color: #efefef;
    .machine__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5rem;
        .header__back{
            width: 7rem;
            height: 7rem;
            border-radius: 50%;
            background-color: $primary;
            display: flex;
            justify-content: center;
            align-items: center;
            .header__back_icon{
                font-size: 5rem;
                color: $light;
            }
        }
        .header__title{
            font-size: 6rem;
            font-weight: 900;
        }
    }
    .machine__content{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 3rem;
        .form{
        background: $light;
        padding: 4rem 4rem 6rem ;
        width: 70%;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        gap: 10rem;
        margin-top: 10rem;
            .form__item{
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 5rem;
                .form__item_label{
                    font-size: 3rem;
                }
                input{
                    width: 95%;
                    font-size: 4rem;
                    padding: 1rem;
                    border: none;
                    outline: none;
                    border-bottom: .2rem solid $dark;
                }
            }
        }
        .submit__btn{
            width: 75%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 4rem;
            text-transform: uppercase;
            font-weight: 600;
            background: $primary;
            padding: 2rem 0;
            border-radius: 2rem;
            color: $light;
        }
    }
    .config__container{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: .6);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .config__content{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width:90%;
            text-align: center;
            gap: 2rem;
            background: $light;
            padding: 3rem 2rem;
            border-radius: 2rem;
            .config__headline{
                color: $primary;
                font-size: 5rem;
            }
            .config__item{
                font-size: 3rem;
            }
            .config__contact{
                margin-top: 3rem;
                font-size: 3rem;
                font-weight: 600;
                width: 80%;
            }
            .config__btn_container{
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 100%;
                margin-top: 7rem;
                .config__btn{
                    width: 30%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 4rem;
                    padding: 1rem 2rem;
                    border-radius: 2rem;
                    &.confirm{
                        background: $primary;
                        color:$light ;
                    }
                    &.cancel{
                        color: $primary;
                    }
                }
            }
        }

    }
}
