//COLORES
$primary: #FF7E36;
$secondary:#333A73;
$light:#FFF;
$dark:#000;
$softLight:#F6F6F6;
$teal:#018F8C;
$success:#3CCF4E;

//VARIABLE
$boxShadow:-10px 10px 5px rgb(217, 217, 217);

@font-face {
    font-family: Robo;
    src: url(../fonts/Roboto-Regular.ttf);
}


html{
    font-size: 62.5%;
}
body{
    font-family: "Robo";
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.transcom__container{
    position: relative;
    width: 100%;
    height: 100vh;
    .transcom__content{
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10rem;
        padding-top: 10rem;
        .transcom__header{
            font-size: 6rem;
            text-transform: uppercase;
            font-weight: 600;
        }
        .transcom__result{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 14rem;
            .transcom__icon_box{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30rem;
                height: 30rem;
                border-radius: 50%;
                .transcom__icon{
                    font-size: 17rem;
                    color: $light;
                }
                &.success{
                    background:$success;
                    &::after{
                        content: "";
                        display: block;
                        width: 35rem;
                        height: 35rem;
                        position: absolute;
                        top: -2;
                        border-radius: 50%;
                        z-index: -1;
                        border: 1.5rem dotted $success;
                        animation: spin 30s linear infinite;
                    }
                }
                &.error{
                    background-color: #D04848;
                    &::after{
                        content: "";
                        display: block;
                        width: 35rem;
                        height: 35rem;
                        position: absolute;
                        top: -2;
                        border-radius: 50%;
                        z-index: -1;
                        border: 1.5rem dotted #D04848;
                        animation: spin 30s linear infinite;
                    }
                }
            }
           .confirm__waiting{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .confirm__waiting_text{
                    font-size: 4rem;
                    text-align: center;
                    line-height: 8rem;
                    font-weight: 600;
                }
            }
            .transcom__btn_box{
                display: flex;
                align-items: center;
                gap: 12rem;
                .transcom__btn{
                    padding: 2rem 10rem;
                    font-size: 4rem;
                    font-weight: 600;
                    border-radius: 2rem;
                    text-decoration: none;
                    text-transform: uppercase;
                    &.cancel{
                        color: $dark;
                    }
                    &.continue{
                        color: $light;
                        background: $primary;
                        box-shadow: $boxShadow;
                    }
                }
            }
        }
    }
    .state{
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 3rem;
        left: 3rem;
        .state__item{
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $dark;
            border: .5rem solid #cbcbcb;
            .state__icon{
                font-size: 3rem;
                color: $dark;
            }
            &.active{
                background-color: $secondary;    
                border: 1.3rem solid $secondary;
            .state__icon{
                font-size: 3rem;
                color: $light;
            }
            }
            &.done{
                background-color: $secondary;
                color: $light; 
                border: .5rem solid $secondary;
                .state__icon{
                    font-size: 3rem;
                    color: $light;
                }
            }
            &.error{
                background: #D04848;
                border: .5rem solid #D04848;
                .state__icon{
                    font-size: 3rem;
                    color: $light;
                }
            }
        }
        .state__line{
            width: 4rem;
            height: .3rem;
            background-color: #000;
        }
    }
    .count__down{
        position: absolute;
        bottom: 5rem;
        right: 5rem;
        font-size: 3rem;
        font-style: italic;
        display: flex;
        align-items: center;
        gap: .5rem;
    }
    .fail__count{
        position: absolute;
        bottom: 5rem;
        right: 5rem;
        font-size: 2.5rem;
        font-style: italic;
        display: flex;
        align-items: center;
        gap: .5rem;
        background: $primary;
        padding: 2rem 3rem;
        border-radius: 2rem;
        color: $light;
    }
    .valid{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba($color: #000000, $alpha: 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        .valid__content{
            background: $light;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 7rem 4rem;
            border-radius: 2rem;
            gap: 5rem;
            .valid__icon{
                font-size: 10rem;
                color: #D04848;
            }
            .valid__headline{
                font-size: 4rem;
                text-align: center;
                font-weight: 600;
                width: 90%;
                line-height: 6rem;
            }
            .valid__btn{
                background: $primary;
                color: $light;
                font-size: 3rem;
                padding: 1.5rem 4rem;
                text-transform: uppercase;
                font-weight: 600;
                border-radius: 1rem;
            }
        }
    }
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  