//COLORES
$primary: #FF7E36;
$secondary:#333A73;
$light:#FFF;
$dark:#000;
$softLight:#F6F6F6;

//VARIABLE
$boxShadow:-10px 10px 5px rgb(217, 217, 217);

@font-face {
    font-family: Robo;
    src: url(../fonts/Roboto-Regular.ttf);
}


html{
    font-size: 62.5%;
}
body{
    font-family: "Robo";
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.admin__login_container{
    background: #FFF;
    width: 100%;
    min-height: 100vh;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10rem;
    .logo{
        margin-top: 5rem;
        width: 20rem;
        height: 20rem;
        object-fit:cover;
    }
    .admin__login_content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 85%;
        height: 50vh;
        box-shadow: $boxShadow;
        background: #f5f5f5;
        padding: 4rem 3rem;
        border-radius: 4rem;
        .admin__login_image{
            width: 50%;
            height: 100%;
            background: $primary;
            border-radius: 3rem;
            overflow: hidden;
        }
        .admin__login_form{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 45%;
            height: 90%;
            .admin__login_header{
                font-size: 5rem;
                font-weight: 600;
                .admin__login_header_reminder{
                    font-size: 2rem;
                    color: #8b8b8b;
                    font-style: italic;
                }
            }
            .admin__login_entering{
                display: flex;
                    gap: 5rem;
                    flex-direction: column;
                    width: 100%;
                .admin__login_input{
                    display: flex;
                    gap: 2rem;
                    flex-direction: column;
                    width: 80%;
                    .input__title{
                        font-size: 3rem;
                    }
                    input{
                        width: 100%;
                        padding: 3rem 2rem;
                        outline: none;
                        background: none;
                        border: none;
                        border-bottom: .2rem solid $dark;
                        font-size: 3rem;
                        font-weight: 600;
                    }
                }
            }
            .admin__login_btn{
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $primary;
                padding: 2rem;
                width: 80%;
                font-size: 3rem;
                border-radius: 2rem;
                font-weight: 900;
                color: $light;
            }
        }
    }
    .logout{
        position: absolute;
        bottom: 4rem;
        right: 5rem;
        background-color: $primary;
        padding: 1.5rem 3rem;
        border-radius: 2rem;
        box-shadow: $boxShadow;
        font-size: 2.5rem;
        text-transform: uppercase;
        font-weight: 600;
        color: $light;
        display: flex;
        align-items: center;
        gap: .5rem;
    }
}