//COLORES
$primary: #FF7E36;
$secondary:#333A73;
$light:#FFF;
$dark: #000;
$success:#2ea03b;
$transition: all ease-in-out 2s;


//VARIABLE
$boxShadow:-10px 10px 5px rgb(217, 217, 217);

@font-face {
    font-family: Robo;
    src: url(../fonts/Roboto-Regular.ttf);
}


html{
    font-size: 62.5%;
}
body{
    font-family: "Robo";
}

.home__container{
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    // background-color:$primary ;
    .home__content{
        .header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .header__headline{
                display: flex;
                align-items: center;
                gap: 3rem;
                .headline__box{
                    width: 1.5rem;
                    height: 15rem;
                    background-color: $primary;
                    border-top-right-radius: 2rem;
                    border-bottom-right-radius: 2rem;
                }
                .headline__text{
                    font-size: 5rem;   
                    font-weight: 900;          
                }
            }
            .header__balance{
                display: flex;
                align-items: center;
                background-color: $light;
                box-shadow: $boxShadow;
                border: .3rem solid $dark;
                border-right-color: transparent;
                gap: 3rem;
                padding: 2rem 3rem;
                border-top-left-radius: 2rem;
                border-bottom-left-radius: 2rem;
                .balance__icon{
                    color: $primary;
                    font-size: 3rem;
                }
                .balance__number{
                    font-size: 4rem;
                    font-weight: 900;
                }
            }
        }
    }
    .state{
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 3rem;
        left: 3rem;
        .state__item{
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $dark;
            border: .5rem solid #cbcbcb;
            .state__icon{
                font-size: 3rem;
                color: $dark;
            }
            &.active{
                background-color: $secondary;    
                border: 1.3rem solid $secondary;
            .state__icon{
                font-size: 3rem;
                color: $light;
            }
            }
            &.done{
                background-color: $secondary;
                color: $light; 
                border: .5rem solid $secondary;

                .state__icon{
                    font-size: 3rem;
                    color: $light;
                }
            }
            &.error{
                background: #D04848;
                .state__icon{
                    font-size: 3rem;
                    color: $dark;
                }
            }
        }
        .state__line{
            width: 4rem;
            height: .3rem;
            background-color: #000;
        }
    }
    .currency{
        color: $primary;
        font-weight: 600;
        margin-left: 1rem;
        text-transform: uppercase;
    }
    .logout{
        position: absolute;
        bottom: 4rem;
        right: 5rem;
        background-color: $primary;
        padding: 1.5rem 3rem;
        border-radius: 2rem;
        box-shadow: $boxShadow;
        font-size: 2.5rem;
        text-transform: uppercase;
        font-weight: 600;
        color: $light;
        display: flex;
        align-items: center;
        gap: .5rem;
    }
    .transaction{
        position: absolute;
        bottom: 4rem;
        right: 37rem;
        text-transform: uppercase;
        font-weight: 600;
        color: $light;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        .transaction__item{
            padding: 1.5rem 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 1rem;
            box-shadow: $boxShadow;
            &.topup{
                background: $success;
            }
            &.withdraw{
                background: $light;
                border: .2rem solid $success;
            }
            .transaction__headline{
                font-weight: 600;
                font-size: 2rem;
                &.topup{
                    color: $light;
                }
                &.withdraw{
                    color: $success;
                }
            }
            .dots{
                display: flex;
                align-items: center;
                gap: .5rem;
                .dot{
                    position: relative;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    animation: dotMoveHome 2s forwards infinite;
                    transition: $transition;
                    top: 0;
                    &.first{}
                    &.second{
                        animation-delay: .2s;
                    }
                    &.third{
                        animation-delay: .4s;
                    }
                    &.topup{
                        background: $light;
                    }
                    &.withdraw{
                        background: $success;
                    }
                }
            }
        }
    }
    .valid{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba($color: #000000, $alpha: 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        .valid__content{
            background: $light;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 7rem 4rem;
            border-radius: 2rem;
            gap: 5rem;
            .valid__icon{
                font-size: 10rem;
                color: #D04848;
            }
            .valid__headline{
                font-size: 4rem;
                text-align: center;
                font-weight: 600;
                width: 90%;
                line-height: 6rem;
            }
            .valid__btn{
                background: $primary;
                color: $light;
                font-size: 3rem;
                padding: 1.5rem 4rem;
                text-transform: uppercase;
                font-weight: 600;
                border-radius: 1rem;
            }
        }
    }
}

@keyframes dotMoveHome {
    0%{
        top: .5rem;
    }
    50%{
        top: -1rem;
    }
    100%{
        top: .5rem;
    }
}