//COLORES
$primary: #FF7E36;
$secondary:#333A73;
$light:#FFF;
$dark:#000;
$softLight:#F6F6F6;

//VARIABLE
$boxShadow:-10px 10px 5px rgb(217, 217, 217);

@font-face {
    font-family: Robo;
    src: url(../fonts/Roboto-Regular.ttf);
}


html{
    font-size: 62.5%;
}
body{
    font-family: "Robo";
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.payment__container{
    position: relative;
    width: 100%;
    height: 100vh;
    .payment__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5rem;
        .header__back{
            width: 7rem;
            height: 7rem;
            border-radius: 50%;
            background-color: $primary;
            display: flex;
            justify-content: center;
            align-items: center;
            .header__back_icon{
                font-size: 5rem;
                color: $light;
            }
        }
        .header__title{
            font-size: 6rem;
            font-weight: 900;
        }
    }
    .payment__content{
        max-width: 950px !important;
        margin: 0 auto;
        padding: 0 10rem;
        .payment__summary{
            margin-top: 4rem;
            .payment__summary_content{
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: $light;
                border: .3rem solid $dark;
                padding: 5rem 2rem;
                border-radius: 2rem;
                box-shadow: $boxShadow;
                .summary__image{
                    width: 20%;
                    height: 100%;
                    border-radius: 1rem;
                }
                .summary__info{
                    width: 70%;
                    height: 23rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .summary__price{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .info__item{
                            font-size: 3.5rem;
                            font-weight: 600;
                        }
                    }
                    .summary__line{
                        width: 100%;
                        height: .5rem;
                        background-color: #E2E3E7;
                    }
                    .summary__total{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .info__total{
                            font-size: 5.5rem;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .payment__method{
            display: flex;
            flex-wrap: wrap;
            gap: 4rem;
            justify-content: space-between;
            align-items: center;
            margin-top: 15rem;
            .method__item{
                // width: 30%;
                position: relative;
                flex-grow: 1;
                height: 20rem;
                border-radius: 2rem;
                // flex-direction: column;
                gap: 3rem;
                box-shadow: $boxShadow;
                text-decoration: none;
                &.disable{
                    background-color: $softLight;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    padding: 7rem 1rem;

                    .disable__group{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 2rem;
                    }
                    .disable__text{
                        color: #D04848;
                        font-size: 3rem;
                        text-align: center;
                        font-weight: 600;
                        width: 90%;
                        line-height: 5rem;
                    }
                }
                &.able{
                    background-color: $softLight;
                    display: flex;
                justify-content: center;
                align-items: center;
                padding: 2rem 0;

                }
                .method__icon{
                    color: $dark;
                    font-size: 7rem;
                }
                .method__title{
                    font-size: 5rem;
                    font-weight: 900;
                    text-transform: uppercase;
                    &.cash{
                        color: #018F8C;
                    }
                    &.gtc{
                        color: $primary;
                    }
                    &.vnpay{
                        color: #387ADF;
                        font-size: 6rem;
                        .vnpay__vn{
                            color: #F2613F;
                        }
                    }
                }
                .method__coin{
                    display: flex;
                    align-items: center;
                    gap: 2rem;
                    color: $dark
                    ;
                    .method__icon{
                        font-size: 3rem;
                        color: $primary;
                    }
                    .method__coin_balance{
                        font-size: 6rem;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .state{
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 3rem;
        left: 3rem;
        .state__item{
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $dark;
            border: .5rem solid #cbcbcb;
            .state__icon{
                font-size: 3rem;
                color: $dark;
            }
            &.active{
                background-color: $secondary;    
                border: 1.3rem solid $secondary;
            .state__icon{
                font-size: 3rem;
                color: $light;
            }
            }
            &.done{
                background-color: $secondary;
                color: $light; 
                border: .5rem solid $secondary;

                .state__icon{
                    font-size: 3rem;
                    color: $light;
                }
            }
            &.error{
                background: #D04848;
                .state__icon{
                    font-size: 3rem;
                    color: $dark;
                }
            }
        }
        .state__line{
            width: 4rem;
            height: .3rem;
            background-color: #000;
        }
    }
    .currency{
        color: $primary;
        font-weight: 600;
        margin-left: 1rem;
    }
    .logout{
        position: absolute;
        bottom: 4rem;
        right: 5rem;
        background-color: $primary;
        padding: 1.5rem 3rem;
        border-radius: 2rem;
        box-shadow: $boxShadow;
        font-size: 2.5rem;
        text-transform: uppercase;
        font-weight: 600;
        color: $light;
        display: flex;
        align-items: center;
        gap: .5rem;
    }
    .valid{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba($color: #000000, $alpha: 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        .valid__content{
            background: $light;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 7rem 4rem;
            border-radius: 2rem;
            gap: 5rem;
            .valid__icon{
                font-size: 10rem;
                color: #D04848;
            }
            .valid__headline{
                font-size: 4rem;
                text-align: center;
                font-weight: 600;
                width: 90%;
                line-height: 6rem;
            }
            .valid__btn{
                background: $primary;
                color: $light;
                font-size: 3rem;
                padding: 1.5rem 4rem;
                text-transform: uppercase;
                font-weight: 600;
                border-radius: 1rem;
            }
        }
    }
}