//COLORES
$primary: #FF7E36;
$secondary:#333A73;
$light:#FFF;
$dark:#000;
$softLight:#F6F6F6;
//VARIABLES
$transition: all ease-in-out 2s;


.ads__container{
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .ads__layer_click{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 5rem;
        .ads__waiter{
            width: 50rem;
            height: 50rem;
            object-fit: cover;
        }
        .ads__headline{
            font-size: 4rem;
            font-weight: 600;
        }
        .ads__touch{
            width: 20rem;
            height: 20rem;
            border-radius: 50%;
            border: .5rem solid $light;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .ads__touch_circle{
                position: absolute;
                width: 0rem;
                height: 0rem;
                border-radius: 50%;
                border: .5rem solid $dark;
                transition-duration: $transition;
                animation: circleMove 2s forwards infinite;
            }
            .ads__touch_circle_delay{
                width: 0rem;
                height: 0rem;
                border-radius: 50%;
                border: .5rem solid $dark;
                transition-duration: $transition;
                animation: circleMoveSmaller 2s forwards infinite;
                background: $dark;
                // animation-delay: .4s;
            }
            .ads__pointer{
                position: absolute;
                font-size: 7rem;
                bottom: 3rem;
                color: $dark;
            }
        }
    }
}

@keyframes circleMove {
    0%{
        width: 0rem;
        height: 0rem;
        opacity: 1;
    }
    100%{
        width: 12rem;
        height: 12rem;
        opacity: 0;
    }
}
@keyframes circleMoveSmaller {
    0%{
        width: 0rem;
        height: 0rem;
        opacity: 1;
    }
    100%{
        width: 7rem;
        height: 7rem;
        opacity: 0;
    }
}