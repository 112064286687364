//COLORES
$primary: #FF7E36;
$secondary:#333A73;
$light:#FFF;
$dark:#000;
$softLight:#F6F6F6;

//VARIABLE
$boxShadow:-10px 10px 5px rgb(217, 217, 217);
$transition: all .3s ease-in-out;

@font-face {
    font-family: Robo;
    src: url(../fonts/Roboto-Regular.ttf);
}


html{
    font-size: 62.5%;
}
body{
    font-family: "Robo";
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.technical__container{
    position: relative;
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    .tech__header{
        padding-top: 5rem;
        font-size: 5rem;
        width: 90%;
        margin: 0 auto;
        text-align: center;
        text-transform: uppercase;
        font-weight: 900;
    }
    .tech__navbar{
        width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        gap: 5rem;
        margin-top: 10rem;
        .navbar__item{
            width: 45%;
            font-size: 3rem;
            text-transform: uppercase;
            padding: 1.5rem 5rem;
            border-radius: 1rem;
            font-weight: 600;
            transition: $transition;
            text-align: center;
            &::after{
                transition: $transition;
                content: "";
                display: block;
                width: 0%;
                background: $primary;
                height: .6rem;
                margin-top: 2rem;
            }
                &.active{
                    transition: $transition;
                    &::after{
                        transition: $transition;
                        content: "";
                        display: block;
                        width: 100%;
                        background: $primary;
                        height: .6rem;
                        border-radius: 1rem;
                        margin-top: 2rem;

                    }
                }
        }
    }
    .tech__content{
        width: 90%;
        margin: 0 auto;
        margin-top: 10rem;
        .tech__item{
            width: 100%;
            border: .5rem solid $dark;
            padding: 10rem 0;
            border-radius: 2rem;
            box-shadow: $boxShadow;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10rem;
           .item__input{
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .item__input_headline{
                font-size: 3rem;
                font-weight: 600;
            }
            input{
                width: 60%;
                font-size: 4rem;
                outline: none;
                border: none;
                border-bottom: .5rem solid $dark;
                padding: .5rem 2rem;
                font-weight: 600;
                text-align: center;
            }
           }
           .item__btn_save{
            font-size: 4rem;
            text-transform: uppercase;
            background-color: $primary;
            padding:1.5rem 4rem;
            border-radius: 1rem;
            font-weight: 600;
            color: $light;
           }
        }
    }
    .tech__confirm{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba($color: #000000, $alpha: 0.6);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .tech__confirm_content{
            background: $light;
            width: 60%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 4rem 6rem;
            border-radius: 2rem;
            gap: 10rem;
            .tech__confirm_headline{
                font-size: 4rem;
                text-transform: uppercase;
                font-weight: 600;
                text-align: center;
            }
            .phone{
                font-size: 4rem;
                font-weight: 600;
            }
            .tech__confirm_btns{
                display: flex;
                align-items: center;
                gap: 10rem;
                .btn__item{
                    font-size: 4rem;
                    padding: 1rem 3rem;
                    border-radius: 1rem;
                    font-weight: 600;
                    &.cancel{
                        color: $primary;
                    }
                    &.confirm{
                        background: $primary;
                        color: $light;
                    }
                }
            }
        }
    }
    .logout{
        position: absolute;
        bottom: 4rem;
        right: 5rem;
        background-color: $primary;
        padding: 1.5rem 3rem;
        border-radius: 2rem;
        box-shadow: $boxShadow;
        font-size: 2.5rem;
        text-transform: uppercase;
        font-weight: 600;
        color: $light;
        display: flex;
        align-items: center;
        gap: .5rem;
    }
    .test__connection{
        position: absolute;
        bottom: 4rem;
        left: 5rem;
        background-color: $primary;
        padding: 1.5rem 3rem;
        border-radius: 2rem;
        box-shadow: $boxShadow;
        font-size: 2.5rem;
        text-transform: uppercase;
        font-weight: 600;
        color: $light;
        display: flex;
        align-items: center;
        gap: .5rem;
    }
}