//COLORES
$primary: #FF7E36;
$secondary:#333A73;
$light:#FFF;
$dark: #000;
$success:#3CCF4E;


//VARIABLE
$boxShadow:-10px 10px 5px rgb(217, 217, 217);

@font-face {
    font-family: Robo;
    src: url(../fonts/Roboto-Regular.ttf);
}


html{
    font-size: 62.5%;
}
body{
    font-family: "Robo";
}

.qr__container{
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    .qr__content{
       max-width: 950px !important;
       margin: 0 auto;
       display: flex;
       flex-direction: column;
       gap: 15rem;
       justify-content: space-between;
       align-items: center;
       margin-top: 5rem;
       .qr__logo{
        width: 25rem;
        height: 25rem;
        object-fit: cover;
       }
       .qr__box{
        // background: $primary;
        width: 35rem;
        height: 35rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2rem;
        // border: 3rem solid $secondary;
        animation: borderChangeColor 3s forwards infinite;
        box-shadow: $boxShadow;
       }
       .qr__text{
        font-size: 5rem;
        color: $dark;
        font-style: italic;
       }
    }
    .qr__admin{
        position: absolute;
        bottom: 0rem;
        left: 5rem;
        width: 10rem;
        height: 4rem;
        border-radius: 2rem;
        background-color: $primary;
        display: flex;
        justify-content: center;
        align-items: center;
        border: .5rem solid $primary;
        z-index: 1;
        .admin__icon{
            font-size: 2.5rem;
            color: $primary;
        }
    }
    .countdown{
        position: absolute;
        bottom: 10rem;
        right: 5rem;
        background-color: $primary;
        padding: 1.5rem 3rem;
        border-radius: 2rem;
        box-shadow: $boxShadow;
        font-size: 2.5rem;
        text-transform: uppercase;
        font-weight: 600;
        color: $light;
        display: flex;
        align-items: center;
        gap: .5rem;
    }
    .qr__underline{
        position: absolute;
        bottom: 0;
        height: 5rem;
        width: 100%;
        background-color: $primary;
        border-top-left-radius: 5rem;
        border-top-right-radius: 5rem;
    }
}

@keyframes borderChangeColor {
    0%{
        border: .7rem solid $secondary;
    }
    50%{
        border: .7rem solid $primary;
    }
    100%{
        border: .7rem solid $secondary;
    }
}