//COLORES
$primary: #FF7E36;
$secondary:#333A73;
$light:#FFF;
$dark:#000;
$softLight:#F6F6F6;

//VARIABLE
$boxShadow:-10px 10px 5px rgb(217, 217, 217);
$transition: all ease-in-out 2s;


@font-face {
    font-family: Robo;
    src: url(../fonts/Roboto-Regular.ttf);
}


html{
    font-size: 62.5%;
}
body{
    font-family: "Robo";
    scroll-behavior: smooth;
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.money__container{
    background: $softLight;
    width: 100%;
    min-height: 100vh;
    position: relative;
    .money__content{
        .money__header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5rem;
            .header__back{
                width: 7rem;
                height: 7rem;
                border-radius: 50%;
                background-color: $primary;
                display: flex;
                justify-content: center;
                align-items: center;
                .header__back_icon{
                    font-size: 5rem;
                    color: $light;
                }
            }
            .header__title{
                font-size: 6rem;
                font-weight: 900;
            }
        }
        .money__controller{
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            .controller__content{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 15rem;
                &.withdraw{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 10rem;
                    width: 70%;
                    text-align: center;
                    .withdraw__headline{
                        font-size: 4rem;
                        display: flex;
                        flex-direction: column;
                        gap: 5rem;
                        width: 100%;
                        .withdraw__unit{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            background: $light;
                            padding: 4rem;
                            color: $dark;
                            border-radius: 2rem;
                            box-shadow: $boxShadow;
                            .title{
                            }
                            .number{
                                font-weight: 600;
                            }
                        }
                    }
                    .withdraw__btn{
                        font-size: 3rem;
                        padding: 2rem 4rem;
                        background: $primary;
                        color: $light;
                        border-radius: 1rem;
                        text-transform: uppercase;
                        font-weight: 600;
                    }
                }
                &.setup{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 10rem;
                    width: 70%;
                    text-align: center;
                    .setup__previous{
                        display: flex;
                            align-items: center;
                            justify-content: space-between;
                            background: $light;
                            padding: 4rem;
                            color: $dark;
                            border-radius: 2rem;
                            box-shadow: $boxShadow;
                            width: 100%;
                        .title{
                            font-size: 3rem;
                            font-weight: 600;
                        }
                        .number{
                            display: flex;
                            flex-direction: column;
                            gap: 2rem;
                            .gt{
                                font-size: 4rem;
                            font-weight: 600;
                            }
                            .vnd{
                                font-size: 3rem;
                                font-style: italic;
                                color: #c1c1c1;
                            }
                        }
                    }
                    .setup__headline{
                        font-size: 3rem;
                        font-weight: 600;
                    }
                    .setup__current{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        flex-wrap: wrap;
                        gap: 2rem;
                        .item{  
                            width: 25%;
                            padding: 2rem;
                            border-radius: 1rem;
                            background: $light;
                            box-shadow: $boxShadow;
                            display: flex;
                            flex-direction: column;
                            gap: 1rem;
                            .item__currency{
                                &.gt{
                                    font-size: 2rem;
                                font-weight: 600;
                                }
                                &.vnd{
                                    font-size: 1.5rem;
                                    font-style: italic;
                                    color: #c1c1c1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .alert{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba($color: #000000, $alpha: 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        .alert__content{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: $light;
            padding:5rem;
            border-radius: 1rem;
            gap: 10rem;
            .alert__headline{
                font-size: 4rem;
                font-weight: 600;
                text-align: center;
                line-height: 8rem;
            }
            .alert__confirm{
                font-size: 3rem;
                font-weight: 600;
                width: 70%;
                text-align: center;
            }
            .alert__btn{
                display: flex;
                align-items: center;
                gap: 5rem;
                .btn__item{
                    padding: 2rem 7rem;
                font-size: 4rem;
                text-transform: uppercase;
                font-weight: 600;
                border-radius: 1rem;
                    &.cancel{
                        background: $light;
                        color: $primary;
                    }
                    &.confirm{
                        background: $primary;
                        color: $light;
                    }
                }
            }
        }
    }
    .loading{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba($color: #000000, $alpha: 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        .loading__content{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: $light;
            padding:5rem;
            border-radius: 1rem;
            gap: 10rem;
            .loading__headline{
                font-size: 5rem;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5rem;
                flex-direction: column;
                line-height: 7rem;
                .dots{
                    display: flex;
                    align-items: center;
                    gap: 5rem;
                    .dot{
                        position: relative;
                        width: 3rem;
                        height: 3rem;
                        border-radius: 50%;
                        background: $primary;
                        border: .5rem solid $light;
                        animation: dotMove 2s forwards infinite;
                        transition: $transition;
                        top: 0;
                        &.first{}
                        &.second{
                            animation-delay: .2s;
                        }
                        &.third{
                            animation-delay: .4s;
                        }
                    }
                }
            }
        }
    }
}