//COLORES
$primary: #FF7E36;
$secondary:#333A73;
$light:#FFF;
$dark:#000;
$softLight:#F6F6F6;

//VARIABLE
$boxShadow:-10px 10px 5px rgb(217, 217, 217);

@font-face {
    font-family: Robo;
    src: url(../fonts/Roboto-Regular.ttf);
}


html{
    font-size: 62.5%;
}
body{
    font-family: "Robo";
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product__container{
    max-width: 900px !important;
    margin: 0 auto;
    height: 60vh;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    gap: 4.5rem;
    .product__item{
        width: 30%;
        height: 27vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        // box-shadow: $boxShadow;
        border-radius: 2rem;
        transition: .2s all ease-in-out;
        overflow: hidden;
        &:hover{
        transition: .2s all ease-in-out;
        box-shadow: $boxShadow;  
        transform: translateY(-20px);  
        }
        .product__image{
            width: 100%;
            // height: 100%;
            height: 40rem;  
            border-radius: 2rem;
        }
        .product__info{
            background-color: $softLight;
            position: absolute;
            bottom: 0;
            width: 90%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding: 1.5rem 0;
            position: absolute;
            bottom: 1.5rem;
            border-radius: 2rem;
            .product__name{
                font-size: 2rem;
                font-weight: 600;
                padding: 0 1.5rem;
            }
            .product__buy{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 2rem;
                .product__price{
                    font-size: 3rem;
                    font-weight: 600;
                }
                .product__buy_btn{
                    width: 4rem;
                    height: 4rem;
                    border-radius: 50%;
                    background-color: $secondary;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .buy__icon{
                        font-size: 3rem;
                        color: $light;
                    }
                }
            }
        }
        .product__out{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000000, $alpha: 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
            .product__out_text{
                font-size: 4rem;
                font-weight: 600;
                color: $light;
            }
        }
    }
}
.product__info{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    margin-top: 6rem;
    padding: 0 5rem;
    .product__break{
        font-size: 2rem;
        font-weight: 600;
    }
    .pagination{
        display: flex;
        list-style-type: none;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        .page__num_container{
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: .5rem solid $secondary;
            cursor: pointer;
            .page__num{
                font-size: 2rem;
                font-weight: 600;
            }
        }
        .page__active{
            background-color: $secondary;
            color: $light;
        }
        .next{
    
        }
        .page__nav{
            font-size: 5rem;
            color: $secondary;
            cursor: pointer;
        }
    }
    .product__count{
        font-size: 3rem;
        font-weight: 600;
        color: $dark;
    }
}