//COLORES
$primary: #FF7E36;
$secondary:#333A73;
$light:#FFF;
$dark:#000;
$softLight:#F6F6F6;
$softGreen:#019946;
$success:#2ea03b;
$transition: all ease-in-out 2s;

//VARIABLE
$boxShadow:-10px 10px 5px rgb(217, 217, 217); 

@font-face {
    font-family: Robo;
    src: url(../fonts/Roboto-Regular.ttf);
}


html{
    font-size: 62.5%;
}
body{
    font-family: "Robo";
    scroll-behavior: smooth;
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cashing__container{
    width: 100%;
    min-height: 100vh;
    position: relative;
    background-color: #FFF;
    .cashing__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5rem;
        .header__back{
            width: 7rem;
            height: 7rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            &.disable{

            }
            &.able{
                background-color: $primary;
            }
            .header__back_icon{
                font-size: 5rem;
                color: $light;
            }
        }
        .header__title{
            font-size: 6rem;
            font-weight: 900;
        }
    }
    .cashing__content{
        max-width: 950px !important;
        margin: 0 auto;
        padding: 0 10rem;
        .method__transaction{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 2rem;
            .method__cash_summary{
                width: 100%;
                padding: 5rem 5rem;
                display: flex;
                flex-direction: column;
                gap: 5rem;
                border: .5rem solid $softGreen;
                border-radius: 2rem;
                box-shadow: $boxShadow;
                .method__cash_item{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .method__cash_topup_headline{
                        padding:0 3rem;
                        font-size: 4rem;
                        font-weight: 600;
                    }
                    .method__cash_topup_number{
                        padding:0 3rem;
                        font-size: 4rem;
                        font-weight: 600;
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                    }
                }
                .cashing__line{
                    width: 100%;
                    height: .5rem;
                    border-radius: 5rem;
                    background: $dark;
                }
            }
            .method__cash_notice{
                font-size: 3.5rem;
                text-align: center;
                color:$dark;
                font-weight: 600;
                line-height: 7rem;
                margin-top: 2rem;
            }
            .method__cash{
                position: relative;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .layer__faded{
                    width: 100%;;
                    height: 15rem;
                    background: $light;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 2;
                    .money__reader{
                        position: relative;
                        font-size: 3rem;
                        font-weight: 600;
                        transition: all 1.2s ease-in-out;
                        opacity: 1;
                        font-size: 4rem;    
                        top: 5rem;     
                        opacity: 0;
                        background: $softGreen;
                        color: $light;
                        border-radius: 1rem;
                        padding: 1rem 3rem;
                        &.faded {
                        top: -4rem;
                        opacity: 1;  
                        }
                    }
                }
                .layer__faded_in{
                    width: 100%;
                    height: 15rem;
                    background: $light;
                    margin-top: 12rem;
                    z-index: 2;

                }
                .banknote{
                    width: 20%;
                    background: $light;
                    border: .5rem solid $dark;
                    height: 1.5rem;
                    border-radius: 1rem;
                    border-bottom: 1rem solid $dark;
                    z-index: 2;
                }
                .cash{
                    position: absolute;
                    font-size: 12rem;
                    color: $softGreen;
                    animation: moneyMove 2s infinite forwards;
                    transform: rotate(90deg);
                    left: 38.5%;
                    z-index: 1;
                }
            }
            .cashing__topup_btn{
                background: $primary;
                font-size: 4rem;
                padding: 1.5rem 3rem;
                text-transform: uppercase;
                color: $light;
                border-radius: 1rem;
                font-weight: 600;
                box-shadow: $boxShadow;
            }
            .dots{
                display: flex;
                align-items: center;
                gap: 5rem;
                .dot{
                    position: relative;
                    width: 3rem;
                    height: 3rem;
                    border-radius: 50%;
                    background: $primary;
                    border: .5rem solid $light;
                    animation: dotMove 2s forwards infinite;
                    transition: $transition;
                    top: 0;
                    &.first{}
                    &.second{
                        animation-delay: .2s;
                    }
                    &.third{
                        animation-delay: .4s;
                    }
                }
            }
        }
        .withdraw__content{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-weight: 600;
            border-radius: 2rem;
            border: .5rem solid $dark;
            padding:5rem;
            box-shadow: $boxShadow;
            gap: 8rem;
            .withdraw__item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                .headline{
                    font-size: 4rem;
                    width: 40%;
                }
                .number{
                    font-size: 4rem;
                }
                .input__field{
                    font-size: 4rem;
                    width: 50%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    gap: 2rem;
                    input{
                        width: 50%;
                        font-size: 4rem;
                        border: none;
                        outline: none;
                        border-bottom: .5rem solid $dark;
                        text-align: center;
                        font-weight: 600;
                    }
                }
            }
            .withdraw__err{
                font-size: 4rem;
                color: crimson;
                font-weight: 600;
                text-align: center;
            }
            .withdraw__loading{
                font-size: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;
                gap: 5rem;
                line-height: 5rem;
                .dots{
                    display: flex;
                    align-items: center;
                    gap: 5rem;
                    .dot{
                        position: relative;
                        width: 2rem;
                        height: 2rem;
                        border-radius: 50%;
                        animation: dotMoveHome 2s forwards infinite;
                        transition: $transition;
                        top: 0;
                        background: $primary;
                        &.first{}
                        &.second{
                            animation-delay: .2s;
                        }
                        &.third{
                            animation-delay: .4s;
                        }
                    }
                }
            }
            .withdraw__empty{
                width: 80%;
                font-size: 3rem;
                text-align: center;
                line-height: 5rem;
                color: #D04848;
            }
            .withdraw__btn{
                font-size: 4rem;
                background: $primary;
                padding: 1.5rem 4rem;
                border-radius: 1rem;
                font-weight: 600;
                color: $light;
            }
        }
    }
    .cashing__topup_success{
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: rgba($color: #686868, $alpha: .9);
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .topup__success_container{
            width: 80%;
            height: 50%;
            background: $light;
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: column;
            border-radius: 2rem;
            .topup__success_text{
                font-size: 6rem;
                font-weight: 600;
            }
            .topup__success_summary{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 3rem;
                .topup__success_headline{
                    font-size: 4rem;
                }
                .topup__success_number{
                    font-size: 4rem;
                    font-weight: 600;
                }
            }
            .topup__success_btn{
                background: $primary;
                font-size: 4rem;
                padding: 1.5rem 3rem;
                border-radius: 1rem;
                box-shadow: $boxShadow;
                color: $light;
            }
            .topup__logout{
                display: flex;
                align-items: center;
                gap: 1rem;
                font-size: 3rem;
                font-style: italic;
            }
        }
    }
    .cashing__withdraw_success{
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: rgba($color: #686868, $alpha: .9);
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .withdraw__process{
            width: 80%;
            height: 50%;
            background: $light;
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: column;
            border-radius: 2rem;
            .headline{
                font-size: 5rem;
                color: $dark;
                text-align: center;
                font-weight: 600;
                line-height: 10rem;
            }
            .dots{
                display: flex;
                align-items: center;
                gap: 5rem;
                .dot{
                    position: relative;
                    width: 3rem;
                    height: 3rem;
                    border-radius: 50%;
                    background: $primary;
                    border: .5rem solid $light;
                    animation: dotMove 2s forwards infinite;
                    transition: $transition;
                    top: 0;
                    &.first{}
                    &.second{
                        animation-delay: .2s;
                    }
                    &.third{
                        animation-delay: .4s;
                    }
                }
            }
            .withdraw__success_btn{
                background: $primary;
                font-size: 4rem;
                padding: 1.5rem 3rem;
                border-radius: 1rem;
                color: $light;
            }
            .withdraw__logout{
                display: flex;
                align-items: center;
                gap: 1rem;
                font-size: 3rem;
                font-style: italic;
            }
        }

    }
    .valid{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba($color: #000000, $alpha: 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 4;
        .valid__content{
            background: $light;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 7rem 4rem;
            border-radius: 2rem;
            gap: 5rem;
            .valid__icon{
                font-size: 10rem;
                color: #D04848;
            }
            .valid__headline{
                font-size: 4rem;
                text-align: center;
                font-weight: 600;
                width: 90%;
                line-height: 6rem;
            }
            .valid__btn{
                background: $primary;
                color: $light;
                font-size: 3rem;
                padding: 1.5rem 4rem;
                text-transform: uppercase;
                font-weight: 600;
                border-radius: 1rem;
            }
        }
    }
    .logout{
        position: absolute;
        bottom: 4rem;
        right: 5rem;
        background-color: $primary;
        padding: 1.5rem 3rem;
        border-radius: 2rem;
        box-shadow: $boxShadow;
        font-size: 2.5rem;
        text-transform: uppercase;
        font-weight: 600;
        color: $light;
        display: flex;
        align-items: center;
        gap: .5rem;
    }
}

.currency{
    color: $primary;
    font-weight: 600;
    margin-left: 1rem;
}

@keyframes moneyMove {
    0%{
        top: 30rem;
    }
    50%{
        top: 9rem;
    }
    65%{
        top: 9rem;
    }
    100%{
        top: -1rem;
    }
  }

  @keyframes dotMove {
    0%{
        top: 0rem;
    }
    50%{
        top: -2rem
    }
    100%{
        top: 0rem;
    }
  }